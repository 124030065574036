.modal button.close {
  cursor: pointer;
  color: var(--text-color);
  text-shadow: none;
}
.modal button.close:hover {
  color: var(--highlight-color);
}

.modal-content {
  background: var(--modal-bkg-color);
  padding: var(--spacing-5) var(--spacing-6) var(--spacing-7);
  border-radius: var(--border-radius-large);
  box-shadow: var(--box-shadow-small);
  border: none;
}

.modal-header,
.modal-body,
.modal-footer {
  border: none;
  padding: 0;
  margin: 0;
}

.modal-header {
  margin-bottom: var(--spacing-3);
}

.modal-title {
  font: var(--heading-x-small);
  color: var(--text-color);
  margin: 0;
}

.modal-body {
  color: var(--text-color-dim);
}

.modal-footer {
  margin-top: var(--spacing-4);
  gap: var(--spacing-2);
}

.modal-footer > * {
  margin: unset;
}

.modal-header .emoji-mart-emoji {
  position: relative;
  top: 7px;
  margin-left: 12px;
}

.modal-body .emoji-mart-emoji {
  position: relative;
  top: 4px;
}

.modal.show .modal-dialog {
  display: block;
}
