.automation_messages {
  flex-wrap: wrap;
  width: 100%;
  margin-top: var(--spacing-6);
}
.automation_messages__container {
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: 'content trash' 'content plus' 'content noop';
}
.automation_messages__container .content {
  grid-area: content;
}
.automation_messages__container .trash {
  grid-area: trash;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.automation_messages__container .trash a {
  color: var(--gray-3);
  font-size: 24px;
  cursor: pointer;
}
.automation_messages__container .plus {
  grid-area: plus;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.automation-analytics-table-xs {
  font-weight: 600;
  width: 40px;
}
.automation-analytics-table-sm {
  font-weight: 600;
  width: 100px;
}
.automation-cancellation-trigger-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--spacing-1);
  gap: var(--spacing-1);
}
.automation-trigger-filters {
  margin-bottom: var(--spacing-2);
  .segment-filter-group-container {
    > .dropdown:last-child {
      margin-top: var(--spacing-3);
    }
  }
  .filter-criteria-and {
    color: currentColor;
  }
  .object-filter-element:last-child {
    margin-top: var(--spacing-3);
  }
  .clear-button {
    color: var(--highlight-color);
  }
  .add-entry-dropdown {
    margin-top: var(--spacing-4);
  }
  .add-entry-dropdown .btn,
  .add-filter-field-dropdown .btn {
    background: var(--main-bkg-color);
    border-color: var(--field-text-color);
    border-radius: var(--border-radius-x-small);
    color: var(--field-text-color);
    font: var(--body-text-small);
    transition: border-color var(--hover-transition);
    &:active {
      color: unset;
    }
    &:focus {
      box-shadow: 0 0 5px var(--highlight-color) !important;
      background: var(--main-bkg-color);
      border-color: var(--highlight-color);
      color: var(--field-text-color);
    }
    &:hover {
      background: var(--main-bkg-color);
      border-color: var(--highlight-color);
    }
  }
}
.automation-user-filter-yes-no {
  margin-right: var(--spacing-1);
}
