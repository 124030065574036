.scroll-wrapper {
  background-color: var(--main-bkg-color);
  border: 1px solid var(--border-color-dim);
  border-radius: var(--border-radius-small);
  margin-bottom: var(--spacing-3);
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-3);
}
.scroll-wrapper .scroll-wrapper-content {
  margin-bottom: var(--spacing-3);
  height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
}
.scroll-wrapper .scroll-wrapper-content p {
  font: var(--body-text-small);
  color: var(--text-color-dim);
}
.scroll-wrapper .scroll-wrapper-mask {
  width: 100%;
  height: 84px;
  background: linear-gradient(
    to bottom,
    rgba(244, 246, 252, 0) 0%,
    rgba(244, 246, 252, 1) 100%
  );
  transform: translateY(-100%);
  margin-bottom: -84px;
  pointer-events: none;
}
.scroll-wrapper .scroll-wrapper-trigger:hover {
  text-decoration: none;
}
.scroll-wrapper .scroll-wrapper-trigger:hover span {
  text-decoration: underline;
}

/* SCROLLBAR */
/* width */
.phone-content-container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.phone-content-container::-webkit-scrollbar-track {
  background: var(--gray-1);
}

/* Handle */
.phone-content-container::-webkit-scrollbar-thumb {
  background: var(--gray-1);
}

/* Handle on hover */
.phone-content-container::-webkit-scrollbar-thumb:hover {
  background: var(--gray-1);
}
