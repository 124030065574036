[data-theme='dark'] {
  --main-bkg-color: var(--gray-9);
}

@import './bootstrap';
@import './onboarding';

@import './components/clone-button';
@import './components/data-table';
@import './components/dropdown';
@import './components/form-fields';
@import './components/modal';
@import './components/scroll-wrapper';

@import './app/automations';
@import './app/helpers';
@import './app/segment-filters';
@import './app/app-styles';
