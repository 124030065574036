/* Originally based on
https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.css, which was
imported globablly, but only used in onboarding.

The keyframes and classes below are recreations of ones which we actually make
use of, with an `onboarding-animate__` prefix added to make finding references
easier.
*/

@keyframes onboarding-animate__fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.onboarding-animate__fadeIn {
  animation-name: onboarding-animate__fadeIn;
}

@keyframes onboarding-animate__fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.onboarding-animate__fadeInUp {
  animation-name: onboarding-animate__fadeInUp;
}

.onboarding-animate__animated {
  animation-duration: 1s;
  animation-fill-mode: both;

  @media (print), (prefers-reduced-motion: reduce) {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  &.onboarding-animate__delay-3s {
    animation-delay: 3s;
  }

  &.onboarding-animate__delay-4s {
    animation-delay: 4s;
  }

  &.onboarding-animate__faster {
    animation-duration: 500ms;
  }
}
