.form-control-sm {
  font: var(--body-text-x-small);
  min-height: var(--spacing-4);
  padding: 3px 9px;
}
.form-control-md {
  font: var(--body-text-small);
  min-height: var(--spacing-6);
  padding: 6px 15px;
}

.form-control {
  border: 1px solid var(--border-color);
  background: var(--main-bkg-color);
  color: var(--field-text-color);
  border-radius: var(--border-radius-x-small);
  transition: border-color var(--hover-transition);
}

.form-control:disabled,
.form-control[readonly] {
  background: var(--main-bkg-color);
}

.form-control:not(:disabled):hover,
.form-control:not(:disabled):active {
  background: var(--main-bkg-color);
  color: var(--field-text-color);
  box-shadow: none;
  border-color: var(--highlight-color);
}
.form-control:not(:disabled):focus-visible {
  background: var(--main-bkg-color);
  color: var(--field-text-color);
  box-shadow: 0 0 5px var(--highlight-color);
  border-color: var(--highlight-color);
}

input[type='time'].form-control::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}

.switch-sm-container {
  margin-left: 10px;
  margin-right: 12px;
  position: relative;
  top: 6px;
}

.klaviyo-lists-item-container {
  margin-bottom: 15px;
}

.klaviyo-lists-switch-sm-container {
  margin-right: 7px;
  position: relative;
  top: 1px;
}

.klaviyo-lists-switch-label {
  width: calc(100% - 50px);
  font-size: 14px;
}

.automation-once-per-subscriber-switch-container {
  margin-left: 0px;
  margin-right: 10px;
  position: relative;
  top: 4px;
}

.switch-lg-container {
  margin-left: 15px;
  position: relative;
  top: 10px;
}

.switch-lg {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-sm {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.switch-xs {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 14px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

.switch {
  margin-bottom: 0px;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray-3);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-lg:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-sm:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-xs:before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--green-core);
}

input:disabled + .slider {
  cursor: default;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3498db;
}

input:checked + .slider-lg:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

input:checked + .slider-sm:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

input:checked + .slider-xs:before {
  -webkit-transform: translateX(9px);
  -ms-transform: translateX(9px);
  transform: translateX(9px);
}

/* Rounded sliders */
.slider-lg.round {
  border-radius: 34px;
}

.slider-sm.round {
  border-radius: 20px;
}

.slider-xs.round {
  border-radius: var(--border-radius-medium);
}

.slider.round:before {
  border-radius: 50%;
}

/* Date picker */

.DateInput_input {
  background: var(--main-bkg-color);
  border: 1px solid var(--field-border-color);
  color: var(--field-text-color);
  border-radius: var(--border-radius-x-small);
}

.DateInput_input:hover {
  box-shadow: none;
  border-color: var(--highlight-color);
}

.DateInput_input__small {
  height: var(--spacing-6);
  min-height: var(--spacing-6);
  font: var(--body-text-small);
  padding: 6px 0 6px 15px;
}

.DateInput_input__focused {
  box-shadow: none;
  border-color: var(--highlight-color);
}

.DateInput_input::placeholder {
  color: var(--gray-5);
}
.DateRangePickerInput__withBorder,
.SingleDatePickerInput__withBorder {
  border: none;
  border-radius: var(--border-radius-x-small);
  background-color: transparent;
}

.DateRangePickerInput_arrow {
  color: var(--text-color-dim);
  margin: 0 var(--spacing-1);
}

.DateInput {
  background: transparent;
}

.ps-dropdown__menu .DateInput__small {
  width: 120px;
}

/* Bootstrap custom select */

.custom-select {
  background-color: var(--main-bkg-color);
  border: 1px solid var(--field-border-color);
  border-radius: var(--border-radius-x-small);
  color: var(--field-text-color);
}

.custom-select::placeholder {
  color: var(--gray-5);
}
