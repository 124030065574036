/* *
 * TODO:
 * Pull out Bootstrap variables (colors, etc)
 * Pull out component styling (buttons, etc)
 * */

.onboarding {
  height: 100%;

  img {
    max-width: 100%;
  }

  .container {
    @media (min-width: 1200px) {
      max-width: 1170px;
    }
  }

  &.column-wrapper {
    .column-sidebar {
      padding-top: 10.75rem;

      @include media-breakpoint-down(lg) {
        padding-top: 1.5rem;
        text-align: center;
      }

      .onboarding-progress {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;

        @include media-breakpoint-down(lg) {
          display: inline-block;
          margin: 0 auto;
          text-align: center;
        }

        @include media-breakpoint-down(sm) {
          display: block;
          margin: 0;
          text-align: left;
        }

        &::before {
          position: absolute;
          top: 0.5rem;
          bottom: 0.75rem;
          left: 0.25rem;
          transform: translateX(-50%);
          background-color: $geyser;
          width: 1px;
          content: '';

          @include media-breakpoint-down(lg) {
            top: 3px;
            right: 0;
            bottom: auto;
            left: 0;
            transform: none;
            width: auto;
            height: 1px;
          }

          @include media-breakpoint-down(sm) {
            top: 0.5rem;
            right: auto;
            bottom: 0.5rem;
            left: 0.25rem;
            transform: translateX(-50%);
            width: 1px;
            height: auto;
          }
        }

        li {
          position: relative;
          transition: $transition-base;
          padding-left: 1.25rem;
          color: $heather;
          line-height: 1.25;

          @include media-breakpoint-down(lg) {
            display: inline-block;
            padding-top: 1rem;
            padding-right: 0.625rem;
            padding-left: 0.625rem;
            text-align: center;
            font-size: 0.875rem;
          }

          @include media-breakpoint-down(sm) {
            display: block;
            padding-top: 0;
            padding-right: 0;
            padding-left: 1.25rem;
            text-align: left;
          }

          ul {
            display: none;
          }

          &:not(:last-child) {
            margin-bottom: 0.8125rem;

            @include media-breakpoint-down(lg) {
              margin-bottom: 0;
            }

            @include media-breakpoint-down(sm) {
              margin-bottom: 0.8125rem;
            }
          }

          &::before {
            display: block;
            position: absolute;
            top: 0.1875rem;
            left: 0;
            transition: $transition-base;
            border-radius: 100%;
            background-color: $geyser;
            width: 0.5rem;
            height: 0.5rem;
            content: '';

            @include media-breakpoint-down(lg) {
              top: 0;
              left: 50%;
              transform: translateX(-50%);
            }

            @include media-breakpoint-down(sm) {
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }

          &.active {
            color: var(--highlight-color);

            ul {
              display: block;
              margin: 0.75rem 0 -0.25rem;
              padding: 0;
              list-style: none;

              @include media-breakpoint-down(lg) {
                display: none;
              }

              @include media-breakpoint-down(sm) {
                display: block;
              }

              li {
                font-size: 0.875rem;
                padding: 0;
                margin-bottom: 0.375rem;

                &::after,
                &::before {
                  display: none;
                }
              }
            }

            &::before {
              background-color: var(--highlight-color);
              top: 0.375rem;

              @include media-breakpoint-down(lg) {
                top: 0;
              }

              @include media-breakpoint-down(sm) {
                top: 0.375rem;
              }
            }
          }

          &.completed {
            color: $slate;

            &::before {
              left: -3px;
              background-color: var(--highlight-color);
              width: 0.875rem;
              height: 0.875rem;

              @include media-breakpoint-down(lg) {
                top: -4px;
                left: 50%;
              }

              @include media-breakpoint-down(sm) {
                top: 50%;
                left: -3px;
              }
            }

            &::after {
              position: absolute;
              top: 3.5px;
              left: -0.5px;
              color: $white;
              font-family: 'FontAwesome';
              font-size: 10px;
              content: '\f00c';

              @include media-breakpoint-down(lg) {
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
              }

              @include media-breakpoint-down(sm) {
                top: 3px;
                left: -1px;
                transform: none;
              }
            }
          }
        }
      }
    }

    .column-main {
      .column-main-body {
        &.has-illustration {
          background-position: bottom right;
          background-repeat: no-repeat;
          background-size: 510px;

          @include media-breakpoint-down(lg) {
            background-size: 340px;
            padding-bottom: 320px;
          }

          @include media-breakpoint-down(sm) {
            padding-bottom: 320px;
          }

          p {
            padding-right: 5rem;

            @include media-breakpoint-down(lg) {
              padding-right: 0;
            }

            &:last-child {
              margin-bottom: 2rem;
            }
          }
        }
      }
    }
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .no-underline {
    text-decoration: none !important;
  }
}

/* Instruction blocks on Try It Out! page */
.onboarding-tryout-codes {
  @include media-breakpoint-down(sm) {
    margin-top: -2rem;
    margin-bottom: 2rem;
  }

  > div {
    padding: 3.75rem;
    @include media-breakpoint-down(sm) {
      padding: 1.5rem 0;
    }

    &:first-child {
      border-bottom: 2px solid $mist;
    }

    p {
      margin-bottom: 0;

      &.big {
        color: $slate;
        font-size: 1.5rem;
        font-weight: 300;

        strong {
          font-weight: 500;
        }
      }
    }
  }
}
