/* *
 * TODO:
 * Remove .onboarding wrapper
 * Extract to Bootstrap variables
 * */

.onboarding .btn {
  cursor: pointer;
  padding-top: 0.4375rem;
  padding-right: 1.875rem;
  padding-left: 1.875rem;
  font-size: 0.875rem;
  font-weight: 500;

  &:active,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &.btn-primary {
    border-color: $cobalt;
    background-color: $cobalt;

    &:hover {
      border-color: lighten($cobalt, 5%);
      background-color: lighten($cobalt, 5%);
    }

    &:active,
    &:focus {
      border-color: darken($cobalt, 10%);
      background-color: darken($cobalt, 10%);
    }
  }

  &.btn-light {
    border-color: transparent;
    background-color: var(--surface-bkg-color);
    color: var(--text-color);

    &:hover {
      border-color: var(--highlight-color);
      background-color: var(--surface-color);
      color: var(--text-color);
    }

    &:active,
    &:focus {
      border-color: $cobalt;
      background-color: darken($vapor, 5%);
      color: $cobalt;
    }
  }

  &.btn-link {
    font-weight: normal;
    color: $cobalt;

    &:hover {
      color: lighten($cobalt, 5%);
    }

    &:active,
    &:focus {
      color: darken($cobalt, 10%);
    }
  }
}
