/* *
 * TODO:
 * Pull out Bootstrap variables (colors, etc)
 * */

.onboarding.column-wrapper {
  background-color: var(--main-bkg-color);

  .container {
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    padding: var(--spacing-6);

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  .column-sidebar {
    flex: 0 0 240px;
    background-color: var(--main-bkg-color);
    padding: 2.5rem;

    @include media-breakpoint-down(lg) {
      padding: 1.5rem;
      flex: 0 0 auto;
    }
  }

  .column-main {
    flex: 0 0 calc(100% - 240px);
    border-radius: var(--border-radius-large);
    background: var(--surface-bkg-color);
    box-shadow: var(--box-shadow-small);

    @include media-breakpoint-down(lg) {
      flex: 1 1 auto;
    }

    .column-main-body {
      padding: var(--spacing-6) var(--spacing-7) var(--spacing-3);
      min-height: 600px;

      @include media-breakpoint-down(lg) {
        padding: 1.5rem;
        min-height: 0;
      }

      .column-main-body-header {
        margin-bottom: 2.5rem;
      }
    }

    .column-main-footer {
      padding: var(--spacing-3) var(--spacing-7) var(--spacing-6);
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        padding: 1.25rem 1.5rem;
        text-align: left;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      @include media-breakpoint-down(sm) {
        padding-bottom: 6rem;
      }

      .btn {
        &:not(:last-child) {
          margin-right: 0.625rem;
        }
      }

      p {
        font-size: 0.875rem;
        font-style: italic;
        display: inline-block;
        color: $steel;
        margin-right: auto;
        padding-right: 1.5rem;
        margin-bottom: 0;
        text-align: right;

        @include media-breakpoint-down(md) {
          display: block;
          flex: 0 0 100%;
          text-align: left;
          margin-bottom: 1.25rem;
        }

        .text-danger {
          color: $flame !important;
        }

        i {
          &:first-child {
            margin-right: 0.375rem;
          }
        }
      }
    }
  }
}

.global-alert {
  position: fixed;
  top: 0;
  z-index: 9999999;
  left: 50%;
  transform: translate(-50%, 0);
}

.bg-future {
  background-color: var(--blue-1) !important;
}

.page-load-spinner {
  width: 100%;
  height: 100%;
  text-align: center;

  > * {
    width: 100%;
    height: 30px;
    display: inline-block;
    position: relative;
    top: 50%;
    margin-top: -15px;
  }
}
