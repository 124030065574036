/* *
 * TODO:
 * Remove .onboarding wrapper
 * Extract to Bootstrap variables
 * */

.onboarding {
  font-family: var(--body-text-typeface);

  ::selection {
    background-color: var(--purple-core);
    color: var(--white);
  }

  h1 {
    font: var(--heading-small);
    margin-bottom: 0.25rem;
    color: var(--text-color);
  }

  p {
    color: var(--text-color-dim);
    font-size: 1rem;
    font-weight: 400;
  }

  a {
    color: var(--link-color);
    transition: $transition-base;

    &:focus,
    &:hover {
      color: var(--link-color-hover);
    }
  }

  .text-muted {
    color: var(--gray-5) !important;
  }

  .small,
  small {
    font-size: 0.875rem;
  }

  .font-size-base {
    font-size: 0.9375rem;
  }
}

.font-size-base {
  font-size: 0.9375rem;
}

$font-awesome-family: normal normal normal 1em FontAwesome;
