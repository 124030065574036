.segment-filter {
  &__group--readonly {
    border: 1px dotted var(--border-color-dim);
    padding: var(--spacing-3) var(--spacing-4);
    .segment-filter__inputs {
      > span {
        margin-left: 0;
      }
    }
  }
  &__transition {
    .filter-criteria-and {
      color: var(--text-color-dim);
      font-weight: 600;
      > span {
        margin-left: 0;
      }
    }
  }
  &__group,
  &__group--readonly {
    .filter-criteria-and {
      color: var(--text-color-dim);
      font-weight: 600;
      > span {
        margin-left: 0;
      }
    }
  }
  &__group {
    border: 1px dotted var(--border-color);
    padding: var(--spacing-3);
  }
  &__item {
    align-items: center;
    display: flex;
    column-gap: var(--spacing-2);
    margin-block: 0;
  }
  &__inputs {
    align-items: center;
    display: flex;
    gap: var(--spacing-1) var(--spacing-2);
    flex-grow: 1;
    flex-wrap: wrap;
    .filter-secondary-action {
      margin: 0;
    }
    .DateInput_input__small {
      padding: 7px 7px 5px;
    }
  }
  &__actions {
    justify-self: flex-end;
  }
  &__select {
    max-width: 504px;
    width: 100%;
    > div {
      &:hover {
        border: 1px solid var(--link-color);
      }
    }
  }
}
#segment-criteria-container {
  #add-segment-filter-button-container {
    margin-bottom: var(--spacing-2);
  }
}
