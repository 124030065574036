.dropdown-divider {
  margin: var(--spacing-2);
  border-top: 1px solid var(--border-color-dim);
}

.dropdown-toggle.btn-sm {
  font: var(--body-text-x-small);
  min-height: var(--spacing-4);
  padding: 3px 9px;
}
.dropdown-toggle.btn-md {
  font: var(--body-text-small);
  min-height: var(--spacing-6);
  padding: 6px 15px;
}
.dropdown-toggle {
  display: block;
  border: 1px solid var(--border-color);
  background: var(--main-bkg-color);
  color: var(--field-text-color);
  border-radius: var(--border-radius-x-small);
  transition: border-color var(--hover-transition);
  font: var(--body-text-small);
}
.dropdown-toggle:hover,
.dropdown-toggle:active {
  background: var(--main-bkg-color);
  color: var(--field-text-color);
  box-shadow: none;
  border-color: var(--highlight-color);
}

.btn-outline-secondary.dropdown-toggle:focus-visible,
.btn-secondary.dropdown-toggle:focus-visible {
  outline: none;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle,
.dropdown-toggle.btn-secondary:not(:disabled):not(.disabled):focus,
.dropdown-toggle.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle,
.dropdown-toggle:not(:disabled):focus-visible,
.btn-outline-secondary.dropdown-toggle:focus {
  background: var(--main-bkg-color);
  border-color: var(--highlight-color);
  box-shadow: 0 0 5px var(--highlight-color) !important;
  color: var(--field-text-color);
}

.dropdown-menu,
.custom-dropdown-menu {
  background-color: var(--dropdown-bkg-color);
  border-radius: var(--border-radius-medium);
  border: none;
  box-shadow: var(--box-shadow-dropdown);
  padding: var(--spacing-2) var(--spacing-1);
}

.custom-dropdown-menu {
  max-height: 480px;
  overflow: auto;
}

button:not(:disabled).dropdown-item {
  cursor: pointer;
}

.dropdown-item {
  color: var(--text-color);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--border-radius-small);
  background-color: transparent;
  font: var(--body-text-small);
}

.dropdown-item.dim {
  color: var(--text-color-dim);
}

.dropdown-item:hover {
  background-color: var(--dropdown-bkg-hover-color);
  color: var(--link-color);
}

.dropdown-item:active {
  background-color: var(--dropdown-bkg-active-color) !important;
  color: var(--link-color);
}

.dropdown-item:focus-visible {
  background-color: var(--dropdown-bkg-hover-color) !important;
  color: var(--link-color);
  box-shadow: none;
  outline: 0;
}

/* analytics dropdowns */

.ps-dropdown {
  background: none;
  border: none;
  border-bottom: 2px solid var(--gray-3);
  position: relative;
  font: var(--body-text-medium);
  color: var(--text-color);
  padding: var(--spacing-1);
  cursor: pointer;
  transition: border-color var(--hover-transition);
}

.ps-dropdown:after {
  font: normal normal normal 1em FontAwesome;
  content: '\f0d7';
  margin-left: var(--spacing-10);
}

.ps-dropdown:hover {
  border-color: var(--link-color);
}

.ps-dropdown:active {
  border-color: var(--link-color-hover);
}

.ps-dropdown:focus {
  outline: none;
  border-color: var(--link-color);
}

.ps-dropdown__menu {
  background-color: var(--dropdown-bkg-color);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--box-shadow-dropdown);
  color: var(--text-color);
  padding: var(--spacing-2) var(--spacing-1);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  min-width: 256px;
}

.ps-dropdown__menu--right {
  left: auto;
  right: 0;
}

.ps-dropdown__menu:hover {
  cursor: auto;
}

.ps-dropdown__list {
  list-style: none;
}

.ps-dropdown__item {
  background-color: rgba(0, 0, 0, 0);
  border-radius: var(--border-radius-small);
  list-style: none;
  color: inherit;
  cursor: pointer;
  display: block;
  font: var(--body-text-small);
  padding: var(--spacing-1) var(--spacing-2);
  outline: none;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  transition-property: background-color, color;
  transition: var(--hover-transition) var(--transition-easing);
  white-space: nowrap;
  width: 100%;
}

.ps-dropdown__item:hover,
.ps-dropdown__item:focus-visible {
  background-color: var(--dropdown-bkg-hover-color);
  color: var(--link-color);
}

.ps-dropdown__item:active,
.ps-dropdown__item:active:hover {
  background-color: var(--dropdown-bkg-active-color);
}
