.full-width {
  width: 100% !important;
}

.big-badge {
  font-size: 1.2em;
}

.small-badge-width {
  min-width: 110px;
}

.less-big-badge {
  font-size: 16px;
}

.more-badge-pad {
  padding: 10px;
}

.mw-sm {
  max-width: 480px;
}

.float-me-righteously {
  float: right;
}

.float-me-lefteously {
  float: left;
}

.filter-inline {
  display: inline-block;
}

.lift-me-a-lil {
  position: relative;
  top: -1px;
}

.display-linebreaks {
  white-space: pre-line;
}

.white-space-nowrap {
  white-space: nowrap;
}

.no-bottom-margin-needed {
  margin-bottom: -80px;
}

.no-border-list {
  border: none !important;
}

.break-word {
  word-wrap: break-word;
  word-break: break-all;
}

.table-no-top-border {
  border-top: none !important;
}

.ta-left {
  text-align: left;
}

.ta-right {
  text-align: right;
}

.ta-center {
  text-align: center;
}

.gimme-a-lil-room {
  margin-left: 10px;
}

.gimme-a-lil-less-room {
  margin-left: 5px;
}

.gimme-a-lil-more-room {
  margin-left: 20px;
}

.givya-a-lil-room {
  margin-right: 10px;
}

.givya-a-lil-less-room {
  margin-right: 5px;
}

.watch-out-a-lil-below {
  margin-bottom: 5px;
}

.watch-out-a-lil-above {
  margin-top: 5px;
}

.watch-out-a-lil-more-above {
  margin-top: 10px;
}

.watch-out-a-lil-more-below {
  margin-bottom: 10px;
}

.watch-out-a-bit-more-above {
  margin-top: 15px;
}

.watch-out-a-lot-more-above {
  margin-top: 20px;
}

.watch-out-a-lot-more-below {
  margin-bottom: 20px;
}

.btn.disabled {
  pointer-events: none;
}

.hide {
  display: none !important;
}

.d-flex {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center-space-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* animations */

@-webkit-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -158px 0px;
  }
}
@-moz-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -158px 0px;
  }
}
@-ms-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -158px 0px;
  }
}
@keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -158px 0px;
  }
}

/* too generic */
textarea {
  min-height: 90px;
  word-wrap: break-word;
}
