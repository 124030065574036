/* *
 * TODO:
 * Remove .onboarding wrapper
 * Extract to Bootstrap variables
 * */

.onboarding {
  label {
    margin-bottom: 3px;
    font: var(--body-text-small);
    font-weight: 600;
    color: var(--text-color);
    display: block;
  }

  .form-group {
    margin-bottom: 1.25rem;

    .invalid-feedback {
      color: $flame;
    }
  }

  .form-control {
    font: var(--body-text-small);
    background-color: var(--main-bkg-color);

    &::placeholder {
      color: var(--gray-5);
    }

    &.is-valid {
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath fill='%232E5BFF' d='M435.848,83.466l-263.044,263.044l-96.652,-96.652c-4.686,-4.686 -12.284,-4.686 -16.971,0l-28.284,28.284c-4.686,4.686 -4.686,12.284 0,16.971l133.421,133.421c4.686,4.686 12.284,4.686 16.971,0l299.813,-299.813c4.686,-4.686 4.686,-12.284 0,-16.971l-28.284,-28.284c-4.686,-4.686 -12.284,-4.686 -16.97,0Z'/%3e%3c/svg%3e")
        no-repeat right 0.625rem center/14px 14px;
      background-color: var(--main-bkg-color);
      border-color: var(--border-color);
    }

    &.is-invalid {
      border-color: $flame;
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath fill='%23FF4401' d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3e%3c/svg%3e")
        no-repeat right 0.625rem center/14px 14px;
      background-color: rgba($flame, 0.05);
    }
  }

  .custom-select {
    appearance: none;
    -moz-appearance: none;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3e%3cpath fill='%232E5BFF' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3e%3c/svg%3e")
      no-repeat right 0.625rem center/14px 16px;
    height: var(--spacing-6);
    min-height: var(--spacing-6);

    border: 1px solid var(--border-color);
    background-color: var(--main-bkg-color);
    color: var(--field-text-color);
    font: var(--body-text-small);
    border-radius: var(--border-radius-x-small);
    transition: border-color var(--hover-transition);

    &.touched {
      color: $dusk;
    }

    &:disabled {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3e%3cpath fill='%2392A1B4' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3e%3c/svg%3e");
    }

    &:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3e%3cpath fill='%232E5BFF' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3e%3c/svg%3e");
      border-color: $cobalt;
      box-shadow: none;
      outline: none;
    }
  }

  .custom-checkbox {
    &.custom-checkbox-lg {
      padding-left: 0;

      .custom-control-label {
        font-size: 1rem;
        font-weight: 300;
        padding-left: 1.75rem;
        line-height: 1.375;

        &::before {
          content: '\f1db';
          font-family: 'FontAwesome';
          top: -0.1875rem;
          left: 0;
          font-size: 1.25rem;
          border: none !important;
          color: $cobalt;
          font-weight: normal;
          background: transparent !important;
          outline: none !important;
          box-shadow: none !important;
          pointer-events: none;
          cursor: pointer;
        }

        &::after {
          display: none !important;
        }
      }

      .custom-control-input {
        &:not(:checked) {
          ~ .custom-control-label {
            color: inherit;
          }
        }
        &:checked {
          ~ .custom-control-label {
            &::before {
              content: '\f058';
            }
          }
        }
      }
    }
  }

  /* Color chip picker on Onboarding > Branding */
  .color-chip-wrapper {
    display: flex;

    .color-chip {
      height: 2.25rem;
      width: 2.25rem;
      flex: 0 0 2.25rem;
      border: 1px solid $concrete;
      border-radius: 0.25rem;
      margin-right: 0.625rem;
    }
  }

  .scroll-wrapper .scroll-wrapper-trigger {
    margin-right: var(--spacing-2);
  }

  /* Styling for special boxes containing switches */
  .switch-box {
    border: 1px solid $concrete;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.5rem;

    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }

    &:last-child {
      @include media-breakpoint-down(sm) {
        margin-bottom: 1.5rem;
      }
    }

    .switch-box-content {
      padding-right: 1.5rem;
      margin-right: auto;
      flex: 0 0 72%;
      padding: 0.875rem 0.5rem 0.875rem 1.25rem;

      @include media-breakpoint-down(md) {
        flex: 0 0 65%;
      }

      strong {
        font-size: 0.9375rem;
        font-weight: 500;
        color: $dusk;
      }

      p {
        margin-bottom: 0;
        font-size: 0.8125rem;
        font-weight: 300;
      }
    }

    .switch-box-value {
      font-size: 0.9375rem;
      font-weight: 500;
      color: $slate;
      margin-right: 0.875rem;
    }

    .custom-switch {
      label {
        &::before {
          border: none;
          background-color: $heather;
        }

        &::after {
          background-color: white;
        }
      }

      .custom-control-input {
        &:checked {
          ~ label {
            &::before {
              background-color: $cobalt;
            }
          }
        }

        &:focus {
          ~ label {
            &::before {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }
    }

    .switch-box-image {
      flex: 0 0 calc(100% + 0.5rem);

      &:first-child {
        margin-bottom: 1.5rem;
      }
    }

    &.off {
      background-color: $mist;

      .switch-box-content {
        strong {
          color: $heather;
        }

        p {
          color: $storm;
        }
      }
    }

    &.has-image {
      flex-wrap: wrap;
    }
  }

  /* Styling for custom file upload buttons */
  .btn.btn-upload {
    border-color: var(--field-border-color);
  }

  .btn-upload {
    margin-bottom: 0;
    width: 100%;
    height: 36px;

    span {
      width: 100%;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    input {
      height: 0.1;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      width: 0.1;
      z-index: -100000000;
    }

    & > * {
      pointer-events: none;
    }
  }
}

// Dynamic Form Component
.dynamic-form {
  position: relative;

  .loading-spinner-container {
    position: absolute;
    z-index: 999999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.75);
    width: 100%;
    height: 100%;

    > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Billing Forms
#stripe-billing-payment-source-form {
  label {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }

  .form-control {
    margin-bottom: 10px;
  }

  .stripe-payment-form {
    padding-top: 0.65rem !important;
  }
}
