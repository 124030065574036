.onboarding {
  /* Preview of text messages on a phone */
  .phone-preview-wrapper {
    border: 2px solid $concrete;
    border-bottom: none;
    background-color: $mist;
    border-radius: 2.25rem 2.25rem 0 0;
    padding: 0.25rem 0.25rem 0;
    box-shadow: 0 0 1.875rem 0 rgba(46, 91, 255, 0.05);
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    &.tryout-preview {
      min-height: 27rem;
    }

    &.border-bottom-mobile {
      @include media-breakpoint-down(sm) {
        border-bottom: 2px solid $concrete;
        margin-bottom: 3rem;
      }
    }

    .phone-preview {
      border: 2px solid $concrete;
      border-bottom: none;
      background-color: $white;
      border-radius: 2.125rem 2.125rem 0 0;
      position: relative;
      flex: 1;
      height: 100%;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 54%;
        height: 26px;
        top: -2px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $mist;
        border: 2px solid $concrete;
        border-radius: 0 0 5rem 5rem;
      }

      .recipients {
        padding: 3rem 1.25rem 1.25rem;
        border-bottom: 2px solid $mist;
        font-size: 1.1875rem;
        line-height: 1.26;
        color: #e1e7ed;
        font-weight: 500;

        strong {
          color: $slate;
          font-weight: 500;
        }
      }

      .messages {
        padding: 0.625rem 1.25rem 1.25rem;
        pointer-events: none;

        &:first-child {
          padding-top: 103px;
        }

        .message {
          margin-bottom: var(--spacing-3);
          p {
            padding: 0.625rem 1rem;
            border-radius: 1.25rem;
            font-size: 0.875rem;
            line-height: 1.43;
            display: inline-block;
            font-weight: normal;
            overflow-wrap: break-word;
          }

          &.from-me {
            text-align: right;

            p {
              background-color: $slate;
              color: $white;
              max-width: 100%;
              word-wrap: break-word;
            }
          }

          &.from-them {
            text-align: left;

            p {
              background-color: $mist;
              color: $slate;
              max-width: 100%;
              word-wrap: break-word;
            }
          }
        }
      }
    }
  }

  /* Preview branding options during Onboarding */
  .branding-preview {
    border: 1px solid $concrete;
    border-radius: 0.25rem;
    padding: 3rem 4rem;
    text-align: center;

    .branding-preview-logo {
      margin-bottom: 2.25rem;
    }

    .branding-preview-header {
      font-size: 1.25rem;
      font-weight: 600;
      color: $black;
      margin-bottom: var(--spacing-3);
    }

    .branding-preview-button {
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 1.3;
      border-radius: 1.125rem;
      padding: 0.5625rem;
      cursor: pointer;
      width: 100%;
      margin-bottom: 1rem;
    }

    .branding-preview-link {
      font-size: 0.875rem;
      line-height: 1.3;
      margin-bottom: 0;
      cursor: pointer;
      font-weight: normal;
      transition: $transition-base;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  /* Previews shown on Onboarding Keywords page */
  .phone-border-mobile {
    border-right: 2px solid $mist;

    @include media-breakpoint-down(sm) {
      border-right: none;
      border-bottom: 2px solid $mist;
      margin-bottom: 3rem;
    }
  }
}
