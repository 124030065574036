.input-group > label {
  font-weight: 600;
}

.form-group > label {
  font-weight: 600;
}

.table th.table-no-top-border {
  font-weight: 600;
}

.analytics-widget-bottom-row {
  font-weight: 600;
}

#safe-send-preview-checkbox-empty,
#safe-send-campaign-checkbox-empty {
  color: var(--gray-4);
}

#safe-send-campaign-checkbox-full {
  color: var(--highlight-color);
}

#login-register-button-container {
  text-align: right;
  margin-top: 20px;
}

#login-register-switch-button {
  margin-top: 30px;
  text-align: center;
}

.left-side {
  margin-right: 90%;
}

.spin-me-slow {
  animation: spin 5s infinite linear;
}

#account-progress-description {
  margin-left: 30px;
}

.account-usage-progress {
  width: 100px;
}

#account-billing-progress {
  margin-top: 8px;
  width: 100px;
  margin-right: 10px;
}

#account-overview-billing-progress {
  margin: var(--spacing-2) var(--spacing-2) 0 0;
  width: 100px;
}

.account-page-progress-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-1) var(--spacing-3);
  margin-bottom: var(--spacing-1);
}

#plans-page-table {
  background-color: var(--surface-bkg-color);
  tbody > tr > td:first-child {
    font-weight: 600;
  }
  tbody > tr > td:last-child button {
    white-space: nowrap;
  }
}

#account-billing-progress-container {
  position: relative;
  top: 3px;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.small-notch {
  font-size: 0.9rem;
  position: relative;
  top: -0.1rem;
}

.loading-spinner-container {
  font-size: 18px;
  width: 100%;
  text-align: center;
}

.loading-spinner-text {
  margin-left: 7px;
}

.loading-spinner-lg {
  height: 500px;
  padding-top: 200px;
}

.loading-spinner-popup {
  height: 400px;
  padding-top: 160px;
}

.loading-spinner-med {
  height: 200px;
  padding-top: 80px;
}

.loading-spinner-sm {
  height: 100px;
  padding-top: 40px;
}

.loading-spinner-decorate {
  font-size: 14px;
  display: inline-block;
  top: -1px;
  position: relative;
}

.contact-subscriber-opt-out {
  padding: 0.15rem 0.3rem;
  font-size: 0.75rem;
  position: relative;
  top: 0px;
}

.contact-subscriber-status-badge {
  font-size: 1em;
  margin-right: 0.15em;
}

.contact-phone-preview-container {
  position: absolute;
  top: -30px;
}

.opt-in-badge-preview {
  position: relative;
  top: -3px;
  margin-left: 10px;
  margin-right: -2px;
}

.remove-prop-icon {
  font-size: 1.5rem;
  color: var(--text-color-dim);
  position: absolute;
  right: -32px;
  top: 12px;
}

.list-secondary-action,
.list-delete-action {
  margin-right: var(--spacing-2);
}

.filter-read-only-attribute {
  font-size: 0.875rem;
  font-weight: bold;
}

.filter-read-only-expression {
  font-size: 14px;
  margin-left: var(--spacing-1);
}

.filter-read-only-value {
  font-weight: bold;
  font-size: var(--spacing-2);
  margin-left: var(--spacing-1);
}

#automation-filter-criteria-container {
  margin-top: var(--spacing-4);
}

.page-cta-secondary-action {
  margin-right: var(--spacing-2);
}

.filter-secondary-action {
  margin-left: var(--spacing-2);
}

.filter-criteria-and {
  margin: var(--spacing-2) 0px;
}

#create-segment-member-count {
  font-size: var(--spacing-4);
}

#create-segment-member-count-info {
  font-size: 14px;
  color: var(--text-color-dim);
  margin-left: 7px;
}

#view-segment-member-count-info {
  font-size: 14px;
  color: var(--text-color-dim);
  margin-left: 7px;
  position: relative;
  top: -2px;
}

#create-campaign-member-count-info {
  font-size: 20px;
  color: var(--text-color-dim);
  position: relative;
  top: 2px;
}

#preview-campaign-member-count-info {
  font-size: 16px;
  color: var(--text-color-dim);
  position: relative;
  top: 2px;
}

#preview-campaign-credits-info {
  color: var(--text-color-dim);
}

#preview-rollover-credits-info {
  font-size: 16px;
  color: var(--text-color-dim);
  position: relative;
  margin: unset;
}

#last-campaign-preview-section {
  margin-bottom: 30px;
}

#no-segments-message {
  font-style: italic;
  margin-right: 10px;
}

#add-segment-filter-button-container {
  margin-top: 20px;
}

.segment-link {
  font-weight: 700;
  margin-right: 10px;
}

.segment-filter-input {
  max-width: 200px;
}

.segment-filter-group-container {
  border: 1px dotted #001276;
  padding: 20px;
}

#clone-keyword-name-input {
  width: 290px;
}

#landing-page-url-input,
#landing-page-message-input {
  width: 800px;
}

#zero-member-segment-badge {
  background-color: var(--text-color-dim);
}

.main-header-text-container {
  display: inline-block;
}

.main-header-text-container-with-button {
  display: inline-block;
  max-width: calc(100% - 150px);
}

.main-area-header-button {
  margin-left: 10px;
}

.main-right-buttons {
  width: 100%;
  text-align: right;
  padding-right: 120px;
  margin-top: 20px;
}

li.list-group-item.all-borders {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.keyword-action-default-prop-list-item,
.keyword-action-additional-prop-list-item {
  padding: 0.25rem;
}

#customer-list-modal-footer,
.modal-footer-two-buttons {
  width: 100%;
  text-align: right;
}

.preview-campaign-value {
  margin: 0px 10px;
}

.preview-campaign-message-strength {
  margin-left: 10px;
}

.preview-campaign-message-strength-bar {
  position: relative;
  top: -1px;
}

#preview-campaign-footer-buttons {
  margin-top: 20px;
}

#preview-campaign-message-text-container {
  max-width: 600px;
}

#preview-campaign-message-text-container .preview-campaign-value {
  word-wrap: break-word;
}

#automation-messages-footer-buttons {
  margin-top: 20px;
}

#automation-properties-footer-buttons {
  margin-top: 20px;
}

#campaign-segment-dropdown .dropdown,
#campaign-tag-inserter-container .dropdown,
.automation-tag-inserter-container .dropdown,
.automation-coupon-inserter-container .dropdown,
.automation-reply-buy-container .dropdown {
  display: inline-block;
}

#campaign-segment-dropdown .dropdown {
  margin-bottom: var(--spacing-4);
}

#campaign-segment-member-badge,
.tag-dropdown-button-container {
  display: inline-block;
}

.tag-dropdown-button-container {
  margin-left: 10px;
}

.automation-button-container {
  display: inline-block;
}

#create-product-link-button-container,
#add-discount-link-button-container {
  display: inline-block;
  margin-left: 10px;
}

.automation-message-choose-file-container {
  float: right;
  margin-left: 10px;
}

.automation-choose-file-modal-size-warning,
#campaign-uploading-file-size-warning,
.automation-uploading-file-size-warning {
  margin-bottom: 40px;
  font-size: 14px;
}

.automation-choose-file-modal-instructions,
#campaign-uploading-file-description,
.automation-uploading-file-description {
  margin-bottom: 10px;
}

#drag-file-icon,
.automation-drag-file-icon,
.loading-file-icon {
  font-size: 50px;
  margin-bottom: 20px;
}

#campaign-segment-preview-badge {
  margin-right: 10px;
}

.message-strength-docs-link {
  font-size: 14px;
  margin-left: 10px;
}

.campaign-text-area-with-emoji-container .hwt-content {
  width: 580px;
}

.hwt-container textarea {
  border-radius: var(--border-radius-x-small);
  color: var(--field-text-color);
  border: 1px solid var(--border-color);
  padding: var(--spacing-1) 15px;
}
.hwt-container textarea:hover {
  border-color: var(--highlight-color);
  color: var(--field-text-color);
}
.hwt-container textarea:focus {
  border-color: var(--highlight-color);
  color: var(--field-text-color);
}

.hwt-container mark {
  background-color: var(--yellow-1);
}

.hwt-container mark.readonly-merge-tag {
  background-color: rgba(41, 128, 185, 0.1);
}

#campaign-phone-input {
  max-width: 200px;
  display: inline;
}

.automation-choose-file-modal-body-container {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.campaign-width {
  width: 580px;
}

#campaign-message-media-file-container {
  padding: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  width: 100%;
  height: var(--spacing-9);
  background-color: var(--yellow-1);
  border-radius: var(--border-radius-small);
  color: var(--black);
}

#campaign-message-media-file-thumbnail-container {
  vertical-align: top;
}

#popup-media-file-container {
  padding: var(--spacing-1) var(--spacing-2);
  margin-left: var(--spacing-2);
  background-color: var(--yellow-1);
  color: var(--black);
  border-radius: var(--border-radius-small);
}

.automation-message-media-file-container {
  padding: var(--spacing-2);
  margin-bottom: var(--spacing-3);
  width: 350px;
  height: var(--spacing-9);
  background-color: var(--yellow-1);
  color: var(--black);
  border-radius: var(--border-radius-small);
}

.automation-message-media-placeholder-container {
  padding: var(--spacing-3) var(--spacing-2) var(--spacing-2) var(--spacing-2);
  margin-bottom: var(--spacing-3);
  width: 350px;
  height: var(--spacing-9);
  border-radius: var(--border-radius-small);
  text-align: center;
  vertical-align: middle;
}

.automation-message-reply-buy-container {
  padding: var(--spacing-2);
  margin-bottom: var(--spacing-3);
  width: 350px;
  height: var(--spacing-9);
  background-color: rgba(155, 89, 182, 0.1);
  border-radius: var(--border-radius-small);
}

#campaign-message-media-file-thumbnail-img,
.automation-message-media-file-thumbnail-img {
  height: 35px;
}

.popup-media-file-thumbnail-img {
  height: 22px;
}

#include-subscribers-container {
  margin-bottom: var(--spacing-4);
}

#campaign-message-media-file-filename-container {
  margin-left: 10px;
  max-width: 410px;
  overflow: hidden;
  vertical-align: super;
}

.automation-message-media-file-filename-container {
  margin-left: 5px;
  max-width: 260px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
}

.automation-message-reply-buy-product-name-container {
  max-width: 300px;
  overflow: hidden;
  padding-top: 5px;
}

.campaign-chosen-product-container {
  padding: 7px 10px;
  margin-bottom: 7px;
  width: 462px;
  background-color: rgba(52, 152, 219, 0.1);
  border-radius: 5px;
  font-size: 14px;
}

.campaign-chosen-product-name-container {
  max-width: 433px;
  overflow: hidden;
}

#popup-media-file-filename-container {
  margin-left: 10px;
  max-width: 260px;
  overflow: hidden;
  vertical-align: middle;
}

#popup-media-file-filename-text {
  font-size: 14px;
}

#popup-no-logo-chosen-text {
  font-size: 14px;
  margin-left: 15px;
  opacity: 0.75;
}

#popup-no-logo-chosen-read-only {
  opacity: 0.75;
}

#campaign-message-media-file-delete-container,
.automation-message-media-file-delete-container {
  margin-right: var(--spacing-1);
}

#popup-media-file-delete-container {
  margin-right: 5px;
  margin-left: 15px;
}

.automation-test-tag-value {
  width: 230px;
  display: inline;
}

.automation-test-tag-name {
  display: inline;
  margin-right: 20px;
}

#automation-test-tag-container {
  text-align: right;
}

#send-campaign-test-button {
  margin-right: 10px;
}

#send-automation-test-button {
  margin-right: 10px;
}

#edit-automation-properties-button {
  margin-right: 10px;
}

#test-automation-phone-number-container {
  width: 100%;
}

.main-area-header-follow-on-stat {
  margin-left: 60px;
}

#campaign-analytics-body {
  margin-top: 40px;
}

#campaign-delete-schedule-button {
  margin-right: 10px;
}

.dash-performance-cell {
  width: 125px;
}

.dash-percentage-cell {
  width: 95px;
}

.dash-action-cell {
  width: 225px;
}

.keyword-action-cell {
  min-width: 210px;
}

.keyword-action-cell-clone {
  min-width: 260px;
}

.automation-action-cell {
  min-width: 225px;
  display: flex;
  justify-content: flex-end;
}

.tr-status-with-toggle {
  padding-right: 65px;
}

.automation-test-phone-label {
  margin-right: 10px;
}

.choose-plan-list-second-element {
  position: absolute;
  left: 30%;
}

.choose-plan-list-third-element {
  position: absolute;
  left: 60%;
}

#choose-plan-button {
  margin-top: -3px;
}

.automation-criteria-container {
  width: 100%;
  white-space: nowrap;
}

#safe-send-automation-container {
  position: absolute;
  left: 800px;
}

#safe-send-automation-checkbox-container {
  position: relative;
  left: 5px;
}

#safe-send-automation-checkbox-full {
  font-size: 1.5em;
}

#safe-send-automation-checkbox-empty {
  font-size: 1.5em;
}

.edit-mms-pause-btn {
  font-size: 1.2em;
  position: relative;
  top: 1px;
}

.automation-reply-to-skip-checkbox-full,
.automation-reply-to-skip-checkbox-empty {
  font-size: 1.35rem;
  position: relative;
  top: 2px;
}

#safe-send-automation-checkbox-label {
  margin-left: 10px;
  position: relative;
  top: -2px;
}

#create-campaign-safe-send-info-icon {
  margin-left: 7px;
}

#campaign-segment-section-container {
  width: 485px;
}

#safe-send-campaign-checkbox-container {
  position: relative;
  left: 32px;
}

.gorgias-integration-checkbox-container {
  position: relative;
  left: 65px;
}

.carthook-integration-instructions {
  max-width: 800px;
}

.integration-page-header-logo {
  margin-right: 15px;
  max-height: 50px;
  max-width: 150px;
}

#compliance-all-marketing-opt-in-checkbox-container {
  display: inline-block;
}

#compliance-all-marketing-opt-in-checkbox-full,
#compliance-all-marketing-opt-in-checkbox-empty {
  position: relative;
  top: 4px;
}

.compliance-all-marketing-opt-in-certify {
  margin-left: 7px;
  position: relative;
  top: 1px;
}

.checkbox-full,
#safe-send-campaign-checkbox-full,
#compliance-all-marketing-opt-in-checkbox-full,
#safe-send-campaign-checkbox-empty,
#compliance-all-marketing-opt-in-checkbox-empty {
  margin-top: 1px;
  font-size: 1.5em;
}

#safe-send-campaign-checkbox-label {
  margin-left: 10px;
  position: relative;
  top: -2px;
}

#automation-user-filter-container {
  margin-top: 20px;
}

.automation-message-timer-container {
  margin-bottom: 25px;
}

.automation-message-container {
  margin-bottom: 10px;
}

.automation-message-timer-number {
  width: 85px;
  margin-right: 10px;
}

.automation-message-text {
  width: 100%;
}

#add-automation-message-outer-container {
  width: 350px;
  height: 270px;
  border: 1px solid var(--border-color-dim);
  border-radius: var(--border-radius-medium);
  background-color: var(--surface-bkg-color);
  margin-left: 0px;
  margin-top: 40px;
  vertical-align: top;
  margin-right: 60px;
  margin-bottom: 40px;
}

#add-automation-message-inner-container {
  margin-top: 58px;
  position: relative;
  top: 57px;
  left: 102px;
}

#shop-name-area,
.shop-name-area {
  width: 110px;
  height: 28px;
  background: transparent;
  position: absolute;
  z-index: 3;
  top: 5px;
  left: 5px;
}

.preview-campaign-edit-link {
  font-size: 14px;
}

.shop-name-input-container {
  padding: 10px;
}

#shop-name-input {
  width: 100%;
}

.admin-shop-drop {
  width: 175px;
  margin-left: 10px;
  position: relative;
  top: 2px;
  z-index: 4000;
}

.onboarding .admin-shop-drop {
  margin-bottom: 20px;
}

#admin-review-icon-container {
  margin-left: 15px;
}

#admin-review-icon {
  font-size: 1.25rem;
  position: relative;
  vertical-align: bottom;
}

.Select-menu-outer {
  overflow: hidden;
  white-space: nowrap;
}

#switching-shops-outer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  text-align: center;
  z-index: 3;
  background: rgba(44, 62, 80, 0.7);
}

#switching-shops-inner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 32px;
}

.automation-message-item {
  margin-top: 40px;
}

.automation-time-pre-label {
  margin-right: 10px;
  font-size: 1.1rem;
}

.time-selection {
  font-weight: bold;
  color: var(--text-color-dim);
  padding: 10px 15px;
  cursor: pointer;
}

.time-selection-selected {
  font-weight: bold;
  color: var(--white);
  background-color: var(--gray-5);
  padding: 10px 15px;
  cursor: pointer;
}

.time-selection-admin {
  font-weight: bold;
  color: var(--text-color-dim);
  padding: 8px 12px;
  cursor: pointer;
}

.time-selection-admin-choose-dates {
  cursor: pointer;
}

.time-selection-selected-admin {
  font-weight: bold;
  color: var(--white);
  background-color: var(--gray-5);
  padding: 8px 12px;
  cursor: pointer;
}

.time-selection-admin:hover,
.time-selection-selected-admin:hover {
  text-decoration: underline;
}

.dashboard-automations-icon {
  font-size: 32px;
}

.dashboard-empty-data-container {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.dashboard-empty-data-message {
  font-style: italic;
  margin: auto;
  max-width: 800px;
}

.automation-button-outer-container {
  align-items: center;
  gap: var(--spacing-1) var(--spacing-2);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  .automation-button-container > button {
    vertical-align: top;
  }

  .message-more-actions-dropdown > button {
    background-color: var(--btn-color) !important;
    border-radius: var(--border-radius-round);
    border: none;
    color: var(--btn-text-color);
    font: var(--body-text-x-small);
    font-weight: 600;
    padding: calc(var(--spacing-1) / 2) var(--spacing-2);

    &:hover {
      background: var(--btn-color-hover) !important;
      border-color: var(--btn-color-hover) !important;
      color: var(--btn-text-color);
    }
    &:active {
      background: var(--btn-color-active) !important;
      border-color: var(--btn-color-active) !important;
      color: var(--btn-text-color);
    }
    &:focus {
      background: var(--btn-color-hover);
      color: var(--btn-text-color);
      box-shadow: inset 0 0 0 2px var(--btn-color-focus) !important;
    }
  }

  .message-more-actions-dropdown > button:hover,
  .message-more-actions-dropdown > button:focus-visible {
    background-color: var(--btn-color-hover);
    border: none;
    font: var(--body-text-x-small);
    font-weight: 600;
    padding: calc(var(--spacing-1) / 2) var(--spacing-2);
  }

  .message-more-actions-dropdown button:active {
    background-color: var(--btn-color-active);
    box-shadow: none;
  }
}

.automation-tag-inserter-container,
.automation-reply-buy-container {
  display: inline;
}

.automation-tag-inserter-outer-container,
.automation-reply-buy-outer-container,
.automation-discount-link-outer-container {
  margin-bottom: 15px;
  padding: 10px 13px 10px 12px;
  display: inline-block;
}

.automation-reply-to-skip-container {
  margin-bottom: 10px;
}

.arrow-box {
  position: relative;
  background: rgba(41, 128, 185, 0.2);
}
.arrow-box:after,
.arrow-box:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-box:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: rgba(41, 128, 185, 0.2);
  border-width: 10px;
  margin-left: -10px;
}
.arrow-box:before {
  border-color: rgba(194, 225, 245, 0);
  border-width: 16px;
  margin-left: -16px;
}

.tag-tool-container .arrow-box:after,
.tag-tool-container .arrow-box:before {
  left: 176px;
}

.reply-buy-tool-container .arrow-box:after,
.reply-buy-tool-container .arrow-box:before {
  left: 202px;
}

.discount-link-tool-container .arrow-box:after,
.discount-link-tool-container .arrow-box:before {
  left: 203px;
}

.arrow-box .dropdown {
  background-color: white;
}

.reply-buy-qty-input {
  width: 65px;
  margin-right: 13px;
}

.reply-buy-price-input {
  width: 85px;
  margin-right: 8px;
}

.reply-buy-select-container,
.reply-buy-qty-container,
.reply-buy-price-container,
.reply-buy-button-container,
.discount-link-url-container {
  margin-top: 7px;
}

.reply-buy-qty-label,
.reply-buy-price-label,
.reply-buy-product-label,
.reply-buy-variant-label,
.discount-link-url-label,
.discount-link-code-label {
  font-size: 14px;
  margin-right: 6px;
}

.discount-link-url-input {
  width: 190px;
}

.discount-link-code-input {
  width: 131px;
}

.discount-link-button-container {
  margin-left: 10px;
}

.reply-buy-product-select {
  width: 268px;
}

.reply-buy-variant-select {
  width: 272px;
}

.reply-buy-loading-text-container {
  margin-top: 7px;
  font-size: 14px;
  padding-left: 7px;
  width: 267px;
  height: 27px;
}

.campaign-chosen-qty-input {
  width: 100px;
  margin-right: 25px;
}

.campaign-chosen-select-container,
.campaign-chosen-qty-container,
.campaign-chosen-button-container {
  margin-top: 7px;
}

.campaign-chosen-qty-label,
.campaign-chosen-product-label {
  font-size: 14px;
  margin-right: 6px;
}

.campaign-chosen-button-container {
  float: right;
}

.campaign-chosen-product-select {
  width: 407px;
}

.campaign-chosen-variant-select {
  width: 412px;
}

.campaign-chosen-loading-text-container {
  margin-top: 7px;
  font-size: 14px;
  padding-left: 7px;
  width: 267px;
  height: 27px;
}

.campaign-add-product-link-discount-label {
  font-size: 14px;
}

.campaign-chosen-discount-input {
  width: 200px;
  margin-left: 7px;
}

.archived-message-row {
  background: rgba(0, 0, 0, 0.075);
  color: var(--text-color-dim);
}

.phone-outline-image {
  position: absolute;
  z-index: 3;
  pointer-events: none;
}

#automation-phone-container {
  position: sticky;
  top: 20px;
  margin-bottom: 25px;
}

#campaign-phone-container {
  position: absolute;
  top: 0px;
}

.analytics {
  width: 100%;
  max-width: 100%;
  overflow: auto;
}

.analytics-table {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: grid;
  margin-top: var(--spacing-4);
}

.analytics-phone-container {
  position: absolute;
  top: 0px;
  left: 50px;
  margin-left: -16px;
}

.row-with-right-margin {
  padding-right: 100px;
}

.phone-container-placeholder {
  height: 455px;
}

.phone-container-placeholder {
  height: 455px;
}

.phone-content-container {
  position: relative;
  top: 13px;
  left: 15px;
  height: 424px;
  width: 195px;
  padding: 25px 10px 10px 10px;
  overflow: auto;
  background-color: rgba(255, 255, 255, 1);
}

#phone-content-header {
  text-align: center;
  font-size: 10px;
  margin-bottom: 10px;
}

.automation-message-timing-header {
  text-align: center;
  font-size: 10px;
  margin: 5px 0px 10px 0px;
}

.phone-content-body {
  text-align: left;
}

#phone-content-media {
  margin-bottom: var(--spacing-1);
}

#phone-content-media-img {
  max-width: 130px;
  max-height: 210px;
  border-radius: 10px;
  cursor: pointer;
}

#phone-content-media .dynamic-photo-icon {
  font-size: 7em;
}

.phone-content-message {
  display: inline-block;
  max-width: 158px;
  padding: 6px 8px 7px 8px;
  border-radius: 10px;
  line-height: 1.35em;
  font-size: 10px;
  background-color: var(--gray-2);
  color: var(--black);
  margin-bottom: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.transactional-message-label {
  display: inline-block;
  max-width: 158px;
  padding: 3px;
  line-height: 1.35em;
  font-size: 9px;
  color: var(--gray-7);
  margin-bottom: 1px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.phone-content-contact {
  display: inline-block;
  max-width: 158px;
  padding: 10px 8px;
  border-radius: 10px;
  line-height: 1.35em;
  font-size: 10px;
  background-color: var(--surface-bkg-color);
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.phone-content-draft-message {
  display: inline-block;
  max-width: 158px;
  padding: 6px 8px 7px 8px;
  border-radius: 10px;
  line-height: 1.35em;
  font-size: 10px;
  background: repeating-linear-gradient(
    45deg,
    #dee2e6,
    #dee2e6 3px,
    #ecf0f1 3px,
    #ecf0f1 6px
  );
  margin-bottom: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.response-view-image-container {
  margin-bottom: 10px;
}

.response-view-image {
  border-radius: 10px;
  max-width: 130px;
  max-height: 210px;
}

.responses-view-more-new-messages {
  font-size: 12px;
  font-weight: bold;
  margin-left: 2px;
  margin-top: 7px;
}

.phone-content-message .emoji-mart-emoji,
.phone-content-customer-message .emoji-mart-emoji,
.phone-content-draft-message .emoji-mart-emoji {
  position: relative;
  bottom: -4px;
  margin: -6px 0px;
}

.phone-content-customer-message-container {
  width: 100%;
  text-align: right;
}

.phone-content-customer-message {
  display: inline-block;
  max-width: 158px;
  padding: 6px 8px 7px 8px;
  border-radius: 10px;
  line-height: 1.35em;
  font-size: 10px;
  background-color: var(--blue-5);
  color: var(--white);
  margin-bottom: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;
}

.btn-link {
  padding: 0px !important;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.automation-choose-file-button-upload-placeholder {
  height: 38px;
}

#campaign-message-char-counter-container {
  font-size: 14px;
  text-align: right;
  width: 575px;
  margin-top: 6px;
  margin-bottom: 8px;
}

#campaign-message-char-counter {
  color: var(--text-color-dim);
}

.automation-message-strength-container {
  font-size: 14px;
  color: var(--text-color-dim);
  width: 350px;
  margin-top: 5px;
}

#preview-message-send-text {
  padding: 0.15rem 0.3rem;
  font-size: 0.75rem;
  position: relative;
  top: -3px;
  margin-right: 5px;
}

.xs-button {
  padding: 0.15rem 0.3rem;
  font-size: 0.75rem;
}

.bp-icon {
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.best-practices-progress-bar {
  width: 100px;
  position: relative;
  top: 1px;
  margin-left: 8px;
  height: 10px;
}

.campaign-sent-progress-bar {
  width: 80px;
  position: relative;
  top: 1px;
  margin-left: 10px;
  height: 10px;
}

.campaign-sent-progress-bar-dash {
  width: 80px;
  position: relative;
  top: 1px;
  height: 10px;
}

.best-practices-list {
  margin-top: 5px;
}

.best-practices-modal-progress-bar {
  width: 100px;
  position: relative;
  top: 1px;
  margin-left: 8px;
  height: 13px;
}

.info-icon-space-maker {
  margin-left: 5px;
}

.automation-reply-stop-checbox {
  margin-left: 5px;
}

#campaign-reply-stop-info-icon,
.automation-reply-stop-info-icon {
  margin-left: 5px;
  color: var(--text-color-dim);
}

#next-steps-outer-container,
#dashboard-notifications-box-container {
  width: 100%;
  margin-bottom: 25px;
  background-color: rgba(41, 128, 185, 0.2);
  border-radius: 0.25rem;
  padding: 0.75rem 1.25rem;
  font-size: 0.9rem;
}

.carthook-instructions-container {
  margin-bottom: 25px;
  background-color: rgba(41, 128, 185, 0.2);
  border-radius: 0.25rem;
  padding: 0.75rem 1.25rem;
}

#next-steps-open-icon,
#next-steps-close-icon {
  cursor: pointer;
}

#next-steps-body {
  margin-top: 5px;
}

#dashboard-notifications-box-close {
  font-size: 1.25rem;
  cursor: pointer;
}

#dashboard-notifications-box-button {
  margin-right: 10px;
  margin-left: -5px;
}

#next-steps-outer-container ul {
  margin-bottom: 0px;
}

.campaign-text-area-with-emoji-container {
  position: relative;
  width: 100%;
}

.text-area-with-emoji-container {
  position: relative;
}

.campaign-text-area-with-emoji-container ::-webkit-scrollbar,
.text-area-with-emoji-container ::-webkit-scrollbar {
  width: 0;
}

.emoji-picker-container {
  position: absolute;
  bottom: 35px;
  left: calc(100% - 35px);
  z-index: 9999;
}

#campaign-emoji-picker-container {
  position: absolute;
  bottom: 35px;
  left: calc(100% - 35px);
  z-index: 9999;
}

.emoji-button-container {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

#campaign-emoji-button-container {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.emoji-mart {
  width: 285px !important;
}

.close-emoji-icon {
  font-size: 1.2rem;
  position: relative;
  top: -3px;
  right: -2px;
}

#popup-custom-css-button {
  padding: 0;
  margin-top: -0.25rem;
}

#campaign-close-emoji-icon {
  font-size: 1.2rem;
  position: relative;
  top: -3px;
  right: 2px;
}

#campaign-schedule-time-picker {
  width: 125px;
  font-size: 15px;
  line-height: 18px;
  padding: 7px 7px 5px;
  display: inline-block;
}

.compliance-setting-label {
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: inline-block;
}

.CalendarDay__selected {
  background: var(--blue-5);
}

.CalendarDay__selected:hover {
  background: var(--blue-5);
}

.CalendarDay__selected:active {
  background: var(--blue-5);
}

.DateInput_input__focused {
  border-bottom-color: var(--blue-5);
}

.analytics-widget-icon {
  padding: 10px;
  width: 50px;
  text-align: center;
}

.analytics-widget-value {
  padding: 10px;
}

.view-keyword-card {
  width: 210px;
  height: 300px;
  border-radius: var(--border-radius-medium);
  margin-bottom: var(--spacing-4);
  margin-right: var(--spacing-4);
  white-space: normal;
  position: relative;
}
.view-keyword-card.btn-purple {
  background-color: var(--purple-6);
  border-color: var(--purple-6);
  color: var(--white);
}
.view-keyword-card.btn-purple:hover {
  background-color: var(--purple-7);
}
.view-keyword-card.btn-purple:active {
  background-color: var(--purple-8);
}

.view-keyword-card.btn-primary {
  background-color: var(--blue-5);
  border-color: var(--blue-5);
  color: var(--white);
}
.view-keyword-card.btn-primary:hover {
  background-color: var(--blue-6);
}
.view-keyword-card.btn-primary:active {
  background-color: var(--blue-7);
}

.view-keyword-card.btn-purple {
  background-color: var(--purple-6);
  border-color: var(--purple-6);
  color: var(--white);
}

.view-keyword-card.btn-purple:hover {
  background-color: var(--purple-7);
}

.view-keyword-card.btn-purple:active {
  background-color: var(--purple-8);
}

.view-keyword-card.btn-primary {
  background-color: var(--blue-5);
  border-color: var(--blue-5);
  color: var(--white);
}

.view-keyword-card.btn-primary:hover {
  background-color: var(--blue-6);
}

.view-keyword-card.btn-primary:active {
  background-color: var(--blue-7);
}

#view-keyword-add-action {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dee2e6;
  font-size: 20px;
  font-weight: bold;
}

.view-keyword-card-title-container {
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  margin: 5px;
}

.view-keyword-card-title-text {
  margin-left: 10px;
}

.view-keyword-card-body-container-choose {
  margin: 20px 8px;
  font-size: 18px;
  text-align: left;
  word-wrap: break-word;
}

.view-keyword-card-body-container-existing {
  margin: 20px 8px;
  font-size: 18px;
  text-align: left;
  word-wrap: break-word;
}

.view-keyword-card-reply-image-container {
  float: left;
  margin: 2px 9px 3px 0px;
}

.view-keyword-card-reply-image {
  max-width: 75px;
  max-height: 60px;
}

.view-keyword-card-reply-text {
  font-size: 16px;
}

.view-keyword-card-reply-text-extra {
  font-size: 10px;
  margin-top: 10px;
}

.view-keyword-card-request-text {
  margin-bottom: 5px;
}

.keyword-action-description {
  font-size: 20px;
  margin-bottom: 20px;
}

.keyword-action-description-asterisk {
  font-size: 16px;
  font-style: italic;
}

#keyword-action-request-props-table {
  max-width: 800px;
}

#keyword-action-request-props-table td {
  width: 50%;
  padding: 0.5rem;
  position: relative;
}

.http-request-keyword-action-container input,
.http-request-keyword-action-container textarea {
  max-width: 800px;
}

.keyword-action-input-container {
  margin: 10px 0px 20px 0px;
}

.responses-customer-cell {
  width: 240px;
  font-weight: bold;
}

.responses-received-time-cell {
  width: 140px;
}

.grow-list-row-container-inactive {
  opacity: 0.5;
}

.grow-list-row-icon,
.email-list-row-icon {
  text-align: center;
  padding: 0px var(--spacing-2);
  font: var(--heading-small);
}

.grow-list-row-icon .fa-mobile {
  font-size: 60px;
}

.grow-list-row-body,
.email-list-row-body {
  width: calc(100% - 285px);
  text-align: left;
  padding: 5px 10px;
}

.email-list-row-body-full {
  width: calc(100% - 90px);
  text-align: left;
  padding: 5px 10px;
}

.grow-list-row-cta,
.email-list-row-cta {
  width: 200px;
  text-align: center;
  padding: 5px 10px;
}

.grow-list-row-cta-button,
.email-list-row-cta-button {
  min-width: 150px;
}

.grow-row-checkbox-icon {
  margin-right: 8px;
  color: var(--mint-core);
}

.grow-row-secondary-cta {
  font-size: 12px;
  margin-top: 10px;
}

.grow-row-tertiary-cta {
  font-size: 12px;
  margin-top: 5px;
}

.email-list-row-input {
  margin-top: 10px;
}

.shopify-template-code-outer-container {
  text-align: center;
  margin-top: 20px;
}

.shopify-template-code-inner-container {
  text-align: left;
  margin: auto;
  max-width: 1000px;
}

.shopify-template-code-text {
  padding: 20px 25px;
  background-color: var(--yellow-1);
  white-space: pre;
  font-size: 12px;
  overflow: auto;
}

.generate-template-code-instructions {
  font-style: italic;
  max-width: 1000px;
}

.email-campaign-link-container {
  font-size: 32px;
  font-weight: bold;
}

#include-subscribers-checkbox-container,
#reply-action-only-once-checkbox-container {
  margin-left: 5px;
  margin-right: 7px;
  position: relative;
  top: 2px;
}

#include-subscribers-checkbox-container .checkbox-readonly {
  color: var(--purple-4);
}
#include-subscribers-checkbox-container .checkbox-checked {
  color: var(--purple-core);
}
#include-subscribers-checkbox-container .checkbox-unchecked {
  color: var(--gray-3);
}

.analytics-dropdown-outer-container {
  min-width: 180px;
  float: right;
  text-align: left;
}

.popup-input-sm-width {
  width: 120px;
}

.popup-input-xl-width {
  width: 400px;
}

.add-existing-customers-button {
  margin: 60px 0px 20px 0px;
}

.announcement-modal-image-container {
  width: 100%;
  text-align: center;
}

.announcement-modal-image {
  margin: auto;
  max-height: 360px;
  max-width: 100%;
}

.automation-message-modal-image-container {
  width: 100%;
  height: 100%;
}

.automation-message-modal-image {
  margin: auto;
  max-width: 150px;
  max-height: 150px;
}

.ellipsis-button-item {
  cursor: pointer;
}

.notifications-label {
  font-size: 18px;
  margin-left: 10px;
}

.notifications-switch-container {
  position: relative;
  top: 3px;
}

.pagination a {
  cursor: pointer;
}

/* Plain button without borders, for accessible icon controls. */
.clear-button {
  border: 0px;
  background-color: transparent;
  color: var(--gray-2);
  font-size: 20px;
}

.object-filter {
  width: 100%;
  margin: 0 0 1rem 0;
}

/* Adds spacing to different object filter elements. */
.object-filter-element {
  margin: 10px 0;
}

.object-filter-element--first {
  margin: 0 0 10px 0;
}

.object-filter-tooltip {
  display: inline-block;
}

.object-filter-tooltip__icon {
  position: relative;
  background-color: var(--blue-5);
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius-round);
  color: var(--white);
  transform: translateY(25%);
}

/* selects the font awesome icon itself. */
.object-filter-tooltip__icon::before {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

/* temporary dashboard/analytics card styles */
.analytics-widget-top-row {
  margin-bottom: var(--spacing-2);
}

.analytics-card-icon {
  border-radius: var(--border-radius-small);
}

.analytics-card-icon--purple {
  background-color: var(--purple-core);
}

.analytics-card-icon--blue {
  background-color: var(--blue-core);
}

.analytics-card-icon--green {
  background-color: var(--mint-core);
}

.analytics-card-icon--yellow {
  background-color: var(--yellow-core);
}

.analytics-card-icon--orange {
  background-color: var(--orange-3);
}

.analytics-card-icon--red {
  background-color: var(--red-core);
}

/* billing */

#billing-view h4 {
  margin: var(--spacing-8) 0 var(--spacing-4) 0;
}
#billing-view h4:first-child {
  margin-top: 0px;
}
#cost-per-credit {
  position: relative;
}
#cost-per-credit .cost-per-credit-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -14px;
  margin-left: -14px;
  width: 28px;
  height: 28px;
}
#cost-per-credit .no-results {
  font: var(--body-text-small);
  font-weight: 600;
  padding-top: var(--spacing-2);
  text-align: center;
}
#cost-per-credit .cost-per-credit-row {
  margin-top: var(--spacing-2);
  padding-top: var(--spacing-2);
  border-top: 1px solid var(--border-color-dim);
}
#cost-per-credit
  .cost-per-credit-row.cost-per-credit-editing
  .cost-per-credit-created-on,
#cost-per-credit
  .cost-per-credit-row.cost-per-credit-editing
  .cost-per-credit-actions {
  padding-top: var(--spacing-1);
}
#cost-per-credit .cost-per-credit-row .cost-per-credit-status-active {
  color: var(--mint-core);
}
#cost-per-credit .cost-per-credit-row .cost-per-credit-status-inactive {
  color: var(--red-core);
}
.list-group {
  color: var(--text-color);
}

.list-group-item {
  background: var(--surface-bkg-color);
}
