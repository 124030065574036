// New Filter Colors
$grayblue-400: #c0c9d6;
$grayblue-600: #8e9eb2;
$grayblue-700: #5a6989;

// New Onboarding colors
$titanium: #f9f9f9;
$vapor: #f4f6fc;
$mist: #f5f7fb;
$concrete: #e2e5ec;
$geyser: #d0d8e1;
$ghost: #b9c3d0;
$heather: #92a1b4;
$storm: #7385ab;
$steel: #5a6989;
$dusk: #44506a;
$slate: #2c364b;
$cobalt: #5724e9;
$flame: #ff4401;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #3498db; //#2980b9; //#007bff !default;
$light-blue: #e8faff;
$purple: #9b59b6; //#8e44ad; //#6f42c1 !default;
$red: #e74c3c; //#c0392b; //#dc3545 !default;
$red-light: #e74c3c80; //#c0392b; //#dc3545 !default;
$orange: #f39c12; //#fd7e14 !default;
$orange-light: #f39c1280; //#fd7e14 !default;
$yellow: #f1c40f; //#ffc107 !default;
$green: #2ecc71; //#27ae60; //#28a745 !default;
$green-light: #2ecc7180; //#27ae60; //#28a745 !default;
$cyan: #1abc9c; //#16a085; //#17a2b8 !default;
$midnight: #2c3e50;
$midnight-alt: #001276;
$sea: #16a085;

$primary: $blue;
$secondary: $midnight-alt;
$success: $green;
$success-light: $green-light;
$info: $cyan;
$warning: $orange;
$warning-light: $orange-light;
$danger: $red;
$danger-light: $red-light;
$light: $gray-100;
$dark: $gray-900;

$soft-primary: #cfe6fe;

$theme-colors: (
  'medium': $gray-500,
  'orange': $orange,
  'yellow': $yellow,
  'purple': $purple,
  'medium-dark': $gray-600,
  'next-lightest': $gray-200,
  'white': $white,
  'sea': $sea,
  'midnight-alt': $midnight-alt,
);

$border-color: $mist;

// Generate Utility Classes

$color-map: (
  'titanium': $titanium,
  'vapor': $vapor,
  'mist': $mist,
  'concrete': $concrete,
  'geyser': $geyser,
  'ghost': $ghost,
  'heather': $heather,
  'storm': $storm,
  'steel': $steel,
  'dusk': $dusk,
  'slate': $slate,
  'cobalt': $cobalt,
  'flame': $flame,
  'white': $white,
  'grayblue-400': $grayblue-400,
  'grayblue-600': $grayblue-600,
  'grayblue-700': $grayblue-700,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
  'black': $black,
  'blue': $blue,
  'light-blue': $light-blue,
  'purple': $purple,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'cyan': $cyan,
  'midnight': $midnight,
  'midnight-alt': $midnight-alt,
  'sea': $sea,
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'success-light': $success-light,
  'info': $info,
  'warning': $warning,
  'warning-light': $warning-light,
  'danger': $danger,
  'danger-light': $danger-light,
  'error-light': $danger-light,
  'light': $light,
  'dark': $dark,
  'soft-primary': $soft-primary,
);

$text-size-map: (
  xs: 0.7rem,
  sm: 0.8rem,
  md: 0.9rem,
  base: 1rem,
);

$border-map: (
  sm: 1px solid #e2e5ec,
);

$border-radius-map: (
  sm: 4px,
  md: 8px,
  lg: 12px,
);

@mixin generateUtilities($map, $property: 'color') {
  @each $name, $hex in $map {
    &-#{$name} {
      #{$property}: $hex;
    }
  }
}

.border {
  @include generateUtilities($border-map, border);
  @include generateUtilities($color-map, border-color);
}
.rounded {
  @include generateUtilities($border-radius-map, border-radius);
}
.text {
  @include generateUtilities($color-map, color);
  @include generateUtilities($text-size-map, font-size);
}
.bg {
  @include generateUtilities($color-map, background-color);
}
